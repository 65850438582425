<template>
  <div style="color: #1a237e">
    <el-row class="m-5 p-3">
      <el-col :md="12" class="img" style="text-align:center">
        <el-image
          src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/toefl_teacher.png"
          style="width: 85%; height: 85%"
        />
      </el-col>

      <el-col :md="12" class="img">
        <el-image
          src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/toefl_logo.png"
        />
        <div class="mt-3" style="text-align: center; font-size: 30px;">
          <p>{{ $t("TOEFLEric.title") }}</p>
          <p>{{ $t("TOEFLEric.subTitle") }}</p>
        </div>
      </el-col>
    </el-row>
    <hr />

    <!-- section 1 -->
    <div class="m-4 p-3">
      <div class="d-flex">
        <div class="numberCircle mr-3">1</div>
        <div style="font-size: 30px;">
          {{ $t("TOEFLEric.audience.title") }}
        </div>
      </div>
      <el-row class="mt-3">
        <el-col :md="12">
          <el-image
            src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/toefl_section1.png"
          />
        </el-col>
        <el-col :md="12" class="descClass">
          <ul>
            <li>{{ $t("TOEFLEric.audience.1") }}</li>
            <li>{{ $t("TOEFLEric.audience.2") }}</li>
            <li>{{ $t("TOEFLEric.audience.3") }}</li>
            <li>{{ $t("TOEFLEric.audience.4") }}</li>
            <li>{{ $t("TOEFLEric.audience.5") }}</li>
          </ul>
        </el-col>
      </el-row>
    </div>
    <hr />

    <!-- section 2 -->
    <div class="m-4 p-3">
      <div class="d-flex">
        <div class="numberCircle mr-3">2</div>
        <div style="font-size: 30px;">
          {{ $t("TOEFLEric.teacher.title") }}
        </div>
      </div>
      <el-row class="mt-3">
        <el-col :md="12">
          <el-image
            src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/toefl_section2.png"
          />
        </el-col>
        <el-col :md="12" class="descClass">
          <ul>
            <li>{{ $t("TOEFLEric.teacher.1") }}</li>
            <li>{{ $t("TOEFLEric.teacher.2") }}</li>
            <li>{{ $t("TOEFLEric.teacher.3") }}</li>
            <li>{{ $t("TOEFLEric.teacher.4") }}</li>
            <li>{{ $t("TOEFLEric.teacher.5") }}</li>
            <li>{{ $t("TOEFLEric.teacher.6") }}</li>
            <li>{{ $t("TOEFLEric.teacher.7") }}</li>
            <li>{{ $t("TOEFLEric.teacher.8") }}</li>
            <li>{{ $t("TOEFLEric.teacher.9") }}</li>
          </ul>
        </el-col>
      </el-row>
    </div>
    <hr />

    <!-- section 3 -->
    <div class="m-4 p-3">
      <div class="d-flex">
        <div class="numberCircle mr-3">3</div>
        <div style="font-size: 30px;">
          {{ $t("TOEFLEric.textbook.title") }}
        </div>
      </div>
      <el-row class="mt-3">
        <el-col :md="12">
          <el-image
            src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/toefl_section3.png"
          />
        </el-col>
        <el-col :md="12" class="descClass">
          <ul>
            <li>{{ $t("TOEFLEric.textbook.1") }}</li>
            <li>{{ $t("TOEFLEric.textbook.2") }}</li>
            <li>{{ $t("TOEFLEric.textbook.3") }}</li>
            <li>{{ $t("TOEFLEric.textbook.4") }}</li>
            <li>{{ $t("TOEFLEric.textbook.5") }}</li>
            <li>{{ $t("TOEFLEric.textbook.6") }}</li>
          </ul>
        </el-col>
      </el-row>
    </div>
    <hr />

    <!-- section 4 -->
    <div class="m-4 p-3">
      <div class="d-flex">
        <div class="numberCircle mr-3">4</div>
        <div style="font-size: 30px;">
          {{ $t("TOEFLEric.teaching.title") }}
        </div>
      </div>
      <el-row class="mt-3">
        <el-col :md="12">
          <el-image
            src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/toefl_section4.png"
          />
        </el-col>
        <el-col :md="12" class="descClass">
          <ul>
            <li>{{ $t("TOEFLEric.teaching.1") }}</li>
            <li>{{ $t("TOEFLEric.teaching.2") }}</li>
            <li>{{ $t("TOEFLEric.teaching.3") }}</li>
            <li>{{ $t("TOEFLEric.teaching.4") }}</li>
          </ul>
        </el-col>
      </el-row>
    </div>
    <hr />

    <!-- section 5 -->
    <div class="m-4 p-3">
      <div class="d-flex">
        <div class="numberCircle mr-3">5</div>
        <div style="font-size: 30px;">
          {{ $t("TOEFLEric.coaching.title") }}
        </div>
      </div>
      <el-row class="mt-3">
        <el-col :md="12">
          <el-image
            src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/toefl_section5.png"
          />
        </el-col>
        <el-col :md="12" class="descClass">
          <ul>
            <li>{{ $t("TOEFLEric.coaching.1") }}</li>
            <li>{{ $t("TOEFLEric.coaching.2") }}</li>
            <li>{{ $t("TOEFLEric.coaching.3") }}</li>
            <li>{{ $t("TOEFLEric.coaching.4") }}</li>
          </ul>
        </el-col>
      </el-row>
    </div>
    <hr />

    <!-- section 6 -->
    <div class="m-4 p-3">
      <div class="d-flex">
        <div class="numberCircle mr-3">6</div>
        <div style="font-size: 30px;">
          {{ $t("TOEFLEric.comments.title") }}
        </div>
      </div>
      <el-row class="mt-3">
        <el-col>
          <el-image
            src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/toefl_section6.png"
          />
        </el-col>
      </el-row>
    </div>
    <hr />

    <!-- section 7 -->
    <div class="m-4 p-3">
      <div class="d-flex">
        <div class="numberCircle mr-3">7</div>
        <div style="font-size: 30px;">
          {{ $t("TOEFLEric.plans.title") }}
        </div>
      </div>
      <el-row class="mt-3">
        <el-col :md="12">
          <el-image
            src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/toefl_section7_1.png"
          />
        </el-col>
        <el-col :md="12" class="descClass">
          <ul>
            <li>{{ $t("TOEFLEric.plans.1") }}</li>
            <li>{{ $t("TOEFLEric.plans.2") }}</li>
            <li>{{ $t("TOEFLEric.plans.3") }}</li>
          </ul>
        </el-col>
      </el-row>
      <hr />

      <el-row class="mt-3">
        <el-col :md="12">
          <h4 style="text-align: center" class="pb-2">
            {{ $t("TOEFLEric.plans.openClass.title") }}
            <br />{{ $t("TOEFLEric.plans.openClass.subTitle") }}
          </h4>
          <el-image
            src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/toefl_section7_2.png"
          />
        </el-col>
        <el-col :md="12" class="descClass">
          <ul>
            <li>{{ $t("TOEFLEric.plans.openClass.1") }}</li>
            <li>{{ $t("TOEFLEric.plans.openClass.2") }}</li>
            <li>{{ $t("TOEFLEric.plans.openClass.3") }}</li>
            <li>{{ $t("TOEFLEric.plans.openClass.4") }}</li>
          </ul>
        </el-col>
      </el-row>
      <hr />

      <el-row class="mt-3">
        <el-col style="text-align: center">
          <h4 style="text-align: center" class="pb-2">
            {{ $t("TOEFLEric.plans.read") }}
          </h4>
          <el-image
            style="width: 70%; height:70%;"
            src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/toefl_section7_r.png"
          />
        </el-col>
      </el-row>
      <el-row class="mt-3">
        <el-col style="text-align: center">
          <h4 style="text-align: center" class="pb-2">
            {{ $t("TOEFLEric.plans.listen") }}
          </h4>
          <el-image
            style="width: 70%; height:70%;"
            src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/toefl_section7_l.png"
          />
        </el-col>
      </el-row>
      <el-row class="mt-3">
        <el-col style="text-align: center">
          <h4 style="text-align: center" class="pb-2">
            {{ $t("TOEFLEric.plans.speak") }}
          </h4>
          <el-image
            style="width: 70%; height:70%;"
            src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/toefl_section7_s.png"
          />
        </el-col>
      </el-row>
      <el-row class="mt-3">
        <el-col style="text-align: center">
          <h4 style="text-align: center" class="pb-2">
            {{ $t("TOEFLEric.plans.write") }}
          </h4>
          <el-image
            style="width: 70%; height:70%;"
            src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/toefl_section7_w.png"
          />
        </el-col>
      </el-row>
      <el-row class="mt-3">
        <el-col style="text-align: center">
          <h4 style="text-align: center" class="pb-2">
            {{ $t("TOEFLEric.plans.online") }}
          </h4>
          <el-row>
            <el-col :md="12">
              <el-image
                src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/toefl_section7_o1.png"
                style="width: 80%; height:80%;"
              />
            </el-col>
            <el-col :md="12">
              <el-image
                src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/toefl_section7_o2.png"
                style="width: 80%; height:80%;"
              />
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <hr />

    <!-- section 8 -->
    <div class="m-4 p-3">
      <div class="d-flex">
        <div class="numberCircle mr-3">8</div>
        <div style="font-size: 30px;">
          {{ $t("TOEFLEric.features.title") }}
        </div>
      </div>
      <el-row class="mt-3">
        <el-col :md="12">
          <el-image
            src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/toefl_section8.png"
          />
        </el-col>
        <el-col :md="12" class="descClass">
          <ul>
            <li>{{ $t("TOEFLEric.features.1") }}</li>
            <li>{{ $t("TOEFLEric.features.2") }}</li>
            <li>{{ $t("TOEFLEric.features.3") }}</li>
            <li>{{ $t("TOEFLEric.features.4") }}</li>
            <li>{{ $t("TOEFLEric.features.5") }}</li>
          </ul>
        </el-col>
      </el-row>
    </div>
    <hr />

    <!-- section 9 -->
    <div class="m-4 p-3">
      <div class="d-flex">
        <div class="numberCircle mr-3">9</div>
        <div style="font-size: 30px;">
          {{ $t("TOEFLEric.FAQS.title") }}
        </div>
      </div>
      <el-row class="mt-3">
        <el-col class="descClass">
          <ul>
            <li>{{ $t("TOEFLEric.FAQS.1") }}</li>
            <li>{{ $t("TOEFLEric.FAQS.2") }}</li>
            <li>{{ $t("TOEFLEric.FAQS.3") }}</li>
          </ul>
        </el-col>
      </el-row>
    </div>
    <hr />
  </div>
</template>

<script>
export default {
  methods: {
    toRoute(name) {
      this.$router.push({
        name: name
      });
    }
  }
};
</script>

<style lang="scss" scoped>
hr {
  margin-top: 1rem;

  border-top: 2px solid rgba(26, 35, 126, 1);
  margin-left: 30px;
  margin-right: 30px;
}

.numberCircle {
  border-radius: 50%;
  width: 42px;
  height: 42px;

  background: rgba(26, 35, 126, 1);
  border: 2px solid rgba(26, 35, 126, 1);
  color: white;
  text-align: center;
  padding-right: 2px;
  padding-top: 2px;

  font: 30px Arial, sans-serif;
}

.descClass {
  font-size: 20px;
  font-weight: 600;
  padding-left: 40px;
}

@media (max-width: 992px) {
  .descClass {
    padding-left: 0px;
    padding-top: 20px;
  }
}
</style>
